import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["map"]
  static values = { lat: Number, long: Number }

  connect() {
    if (window.google && window.google.maps && window.google.maps.places) {
      this.initMap();
    } else {
      window.addEventListener('load', () => this.initMap());
    }
  }

  initMap(){
    const lat = parseFloat(this.latValue);
    const long = parseFloat(this.longValue);
    const map = new google.maps.Map(this.mapTarget, {
      center: { lat: lat, lng: long },
      zoom: 15
    });
    new google.maps.Marker({
      position: { lat: lat, lng: long },
      map: map
    });
  }
}